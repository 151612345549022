<template>
	<div class="mt-30">
		<div class="table-block" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<table>
				<tbody class="font_Hsemibold">
					<tr>
						<td>
							<AppText weight="600" size="14">ИНН</AppText>
						</td>
						<td>{{ data.tin }}</td>
					</tr>
					<tr>
						<td>
							<AppText weight="600" size="14">Регион</AppText>
						</td>
						<td>{{ data.regionName | translate }}</td>
					</tr>
					<tr>
						<td>
							<AppText weight="600" size="14">Район</AppText>
						</td>
						<td>{{ data.districtName | translate }}</td>
					</tr>
					<tr>
						<td>
							<AppText weight="600" size="14">Адрес</AppText>
						</td>
						<td>{{ data.customerAddress }}</td>
					</tr>
					<tr>
						<td>
							<AppText weight="600" size="14">Расчетный счет</AppText>
						</td>
						<td>{{ data.paymentAccount }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import AppText from "@/components/shared-components/AppText";

export default {
	name: "selection-customer-data-tab",
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	components: {
		AppText,
	},
};
</script>

<style lang="scss" scoped>
.nd_model__list.dropdown:hover {
	border-color: #d8e0e8 !important;
}
.table-block {
	tr {
		td {
			padding: 20px;
			&:first-child {
				width: 35%;
			}
		}
	}
}
</style>
