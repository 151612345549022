<template>
  <div class="d-flex flex-column mb-20">
    <AppText class="mb-15" weight="500" size="13">{{ title }}</AppText>
    <div class="d-flex">
      <a
        v-for="(file, idx) in fileList"
        :key="idx"
        class="mr-30 d-flex align-center link-blue fw-500"
        width
        theme="white"
        @click="download(file.url)"
      >
        <img class="mr-10" width="15" height="18" src="@/assets/icons/file.svg" alt />
        {{ file.name }}
      </a>
    </div>
  </div>
</template>
<script>
import AppButtonA from "@/components/shared-components/AppButtonA";
import AppText from "@/components/shared-components/AppText";

export default {
  name: "download-files",
  props: {
    files: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  components: {
    AppButtonA,
    AppText,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
    };
  },
  methods: {
    download(url) {
      window.location.href = url;
    },
  },
  computed: {
    fileList() {
      let files = this.files.split(";");
      const result = [];

      files = files.filter((file) => file !== "" && file !== "null");
      files.forEach((item) => {
        const fileName = item.split("/");
        result.push({
          name: fileName[fileName.length - 1],
          url: this.baseUrl + item,
        });
      });

      return result;
    },
  },
};
</script>