<template>
	<div class="mt-30">
		<div class="d-flex flex-column mb-30">
			<AppText class="mb-20" weight="500" size="16">
				Тендер по оказанию услуг:
			</AppText>
			<div class="table-block">
				<table>
					<tbody>
						<tr>
							<td width='50%'>
								<AppText weight="600" size="14">
									Форма проведения тендера
								</AppText>
							</td>
							<td>{{ data.tenderFormName | translate }}</td>
						</tr>
						<tr>
							<td width='50%'>
								<AppText weight="600" size="14">
									Требование к участникам
								</AppText>
							</td>
							<td>{{ data.requirementParticipants }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="d-flex flex-column mb-30">
			<AppText class="mb-20" weight="500" size="16">
				Тендер по оказанию услуг:
			</AppText>
			<div class="table-block">
				<table>
					<tbody>
						<tr>
							<td width='50%'>
								<AppText weight="600" size="14">
									{{ data.sourceFinanceName | translate }}
								</AppText>
							</td>
							<td width='50%'>Не бюджет</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Форма обеспечения на участке
								</AppText>
							</td>
							<td>{{ paymentScheduleStatuses[data.quarterly] }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									{{ $t('selection_payment_order') }}
								</AppText>
							</td>
							<td>{{ selectionPaymentOrderList[data.payment_order] }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Срок оплаты авансового платежа
								</AppText>
							</td>
							<td>{{ data.paymentAdvance }} {{ $t('days') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Срок окончания приема предложений
								</AppText>
							</td>
							<td>{{ data.paymentAdvance }} {{ $t('days') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Условия поставки товара
								</AppText>
							</td>
							<td>{{ data.deliveryConditionName | translate }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Адрес доставки товара
								</AppText>
							</td>
							<td>{{ data.regionName | translate }}, {{ data.districtName | translate }}, {{ data.address }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="d-flex mb-30">
			<div class="d-flex flex-column">
				<AppText class="mb-10" weight="400" size="12">
					Стартовая сумма
				</AppText>
				<AppText weight="600" size="14">
					{{ data.price | price }} UZS
				</AppText>
			</div>
			<div class="d-flex flex-column ml-50">
				<AppText class="mb-10" weight="400" size="12">
					{{$t('pledgeAmount')}}
				</AppText>
				<AppText weight="600" size="14">
					{{ data.pledgePrice }} %
				</AppText>
			</div>
		</div>
		<div class="d-flex flex-column">
			<AppText class="mb-15" weight="500" size="14">
				График оплаты
			</AppText>
			<div class="table-block" style="max-width: 460px">
				<table>
					<tbody style="background: #F8FBFF">
						<template v-if="data.planPaymenLists.length > 0">
							<tr v-for="(item, idx) in data.planPaymenLists" :key="idx">
								<td>
									Дата: {{ item.date | moment('DD.MM.YYYY') }} {{ item.dateDo ? `- ${$moment(item.dateDo).format('DD.MM.YYYY') }` : '' }}
								</td>
								<!-- <td>Сумма: {{ item.summ }} UZS</td> -->
								<td>Проценты: {{ item.percent }} %</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import AppText from '@/components/shared-components/AppText';
import {selectionPaymentScheduleList, selectionPaymentOrderList} from '@/static/statusIds';

export default {
	name: 'selection-general-info-tab',
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	components: {
		AppText
	},
	data() {
		return {
			paymentScheduleStatuses: selectionPaymentScheduleList,
			selectionPaymentOrderList
		}
	}
}
</script>