<template>
	<div class="mt-30" v-if="data.length > 0">
		<div class="d-flex flex-column mb-30" v-for="(item, idx) in criteria" :key="idx">
			<AppText class="mb-20" weight="500" size="16">
				{{ idx + 1 }}. {{ item.requirementName }}:
			</AppText>
			<div class="table-block">
				<table>
					<tbody>
						<tr>
							<td  width='50%'>
								<AppText weight="600" size="14">
									Требуемое значение
								</AppText>
							</td>
							<td width='50%'>{{criteriaTypeOfValuesList[idx+1]}}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Обязательность
								</AppText>
							</td>
							<td>{{ item.critical ? $t('critical') : $t('preferably') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Минимальный балл
								</AppText>
							</td>
							<td>{{ item.minBal }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Максимальный балл
								</AppText>
							</td>
							<td>{{ item.maxBal }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Метод оценки
								</AppText>
							</td>
							<td>{{ item.systemic ? $t('systemic') : $t('expert') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Удельный вес
								</AppText>
							</td>
							<td>{{ calculateRelativeBal(item.maxBal) }} %</td>
						</tr>
						<tr>
							<td>
								<AppText weight="600" size="14">
									Подробное описание
								</AppText>
							</td>
							<td>{{ item.criterionDescription }}</td>
						</tr>
						<tr v-if="idx == 2">
							<td>
								<AppText weight="600" size="14">
									Значение
								</AppText>
							</td>
							<td>Не более 6 шт</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import AppText from '@/components/shared-components/AppText';
import {criteriaTypeOfValuesList} from '@/static/statusIds';

export default {
	name: 'selection-criteria-tab',
	props: {
		data: {
			type: Array,
			required: true
		}
	},
	components: {AppText},
	data() {
		return {
			criteriaTypeOfValuesList
		}
	},
	methods: {
		calculateRelativeBal(maxBal) {
			const realPercent = ((maxBal * 100) / this.criteriaMaxBalSum) * 100;
			return Math.round(realPercent) / 100;
		},
	},
	computed: {
		criteriaMaxBalSum() {
			return this.data.reduce((total, item) => {
				return {maxBal: total.maxBal + item.maxBal};
			}).maxBal;
		},
		criteria() {
			return this.data.map(criterion => {
				criterion['percent'] = this.calculateRelativeBal(criterion.maxBal);
				return criterion;
			});
		}
	}
}
</script>