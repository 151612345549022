<template>
	<div class="mt-30">
		<div class="d-flex flex-column">
			<AppText class="mb-20 font_Hsemibold" weight="600" size="16">
				Заказчик - Частное предприятие
			</AppText>
			<div class="table-block mb-30">
				<table>
					<tbody>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Ф.И.О. руководителя имеющего право подписи договора
								</AppText>
							</td>
							<td>{{ data.fullnameContract }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Процент предварительной оплаты
								</AppText>
							</td>
							<td>{{ data.paymentAbout }} %</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Срок предварительной оплаты
								</AppText>
							</td>
							<td>{{ data.paymentAdvance }} {{ $t('days') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Срок оплаты после приемки исполнения всех условий договора
								</AppText>
							</td>
							<td>{{ data.paymentTermAfter }} {{ $t('days') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Пеня по факту исполнения части обязательств
								</AppText>
							</td>
							<td>{{ data.paymentPenyaProcent }} %</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Пеня по факту просрочки оплаты
								</AppText>
							</td>
							<td>{{ data.penaltyLatePayment }} %</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Размер штрафа в случае некачественного товара, работ или услуг
								</AppText>
							</td>
							<td>{{ data.fineDefectiveGoods }} %</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Срок оформления рекламного акта
								</AppText>
							</td>
							<td>{{ data.registrationAct }} {{ $t('days') }}</td>
						</tr>
						<tr>
							<td>
								<AppText weight="500" size="14" class="font_Hsemibold">
									Срок форс-мажорных обстоятельств
								</AppText>
							</td>
							<td>{{ data.termForceMajeure }} {{ $t('days') }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import AppText from '@/components/shared-components/AppText';

export default {
	name: 'selection-contract-tab',
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	components: {
		AppText
	},
	data() {
		return {}
	}
}
</script>