 <template>
	<div class="table-block auction-table-wrap mb-20">
		<table class="auction-table">
			<thead>
			<tr v-if="!$slots['header']">
				<th>№</th>
				<th style="max-width: 300px;">Наименование</th>
				<th style="max-width: 300px;">{{$t('amountShort')}}</th>
				<th style="max-width: 300px;">Ед.Изм.</th>
				<th style="max-width: 150px;">Стартовая цена за единицу</th>
				<th style="max-width: 300px;">Стартовая цена</th>
				<th style="max-width: 300px;">Подробное описание товара</th>
			</tr>
				<slot name="header" />
			</thead>
			<tbody>
				<template >
					<template >
						<tr v-for="(item, idx) in data" :key="idx">
							<td style="max-width: 300px; background: #F3F8FC">
								<AppText weight="600" size="14">{{ idx + 1 }}</AppText>
							</td>
							<td style="max-width: 300px; background: #F3F8FC">
								<AppText weight="600" size="14">{{ item.productName | translate }}</AppText>
							</td>
							<td style="max-width: 300px;">{{ item.quantity }}</td>
							<td style="max-width: 300px;">{{ item.measureName | translate }}</td>
							<td style="max-width: 300px;"><b>{{ item.price | price }} UZS</b><br /></td>
							<td style="max-width: 300px;"><b>{{ (item.price * item.quantity) | price }} UZS</b></td>
							<td style="max-width: 300px;">{{ item.detailedDescription }}</td>
						</tr>
					</template>
					<slot name="body" />
				</template>
				
				
			</tbody>
			<tfoot>
					<tr>
					<td colspan="7" style="background: #E9F9F0;">
						<AppText class="color-success text-center font_Hsemibold" weight="600" size="16"
							>Итого стартовая стоимость: {{ totalSum | price }} UZS</AppText
						>
					</td>
				</tr>
				</tfoot>
		</table>
	</div>
</template>
<script>
import AppText from '@/components/shared-components/AppText';

export default {
	name: 'selection-details-table',
	props: {
		data: {
			type: Array,
		}
	},
	components: {AppText},
	data() {
		return {}
	},
	computed: {
		totalSum() {
			let price = 0;

			this.data.forEach(item => {
				price += item.quantity * item.price;
			});

			return price;
		}
	}
}
</script>
<style lang="scss" scoped>
.auction-table {
  min-width: 100%;
  border-spacing: 0;

  th {
    background: #1faec1;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 0 20px;
    height: 60px;
    color: #fff;
    text-transform: uppercase;
    border-right: 1px solid rgba(69, 186, 203);
    &:first-child {
      border-radius: 8px 0 0 0;
      text-align: left;
    }

    &:last-child {
      border-right: 0;
      border-radius: 0 8px 0 0;
    }
  }

  td {
    padding: 14px 20px;
    height: 63px;
    min-width: 90px;
    text-align: center;
    border-bottom: 1px solid #dfeaf3;
    border-right: 1px solid #dfeaf3;
    font-size: 14px;

    &:first-child {
      border-left: 1px solid #dfeaf3;
      background-color: #f3f8fc;
      text-align: left;
      font-weight: 600;
    }
  }

  tr:last-child {
    td {
      &:first-child {
        border-radius: 0 0 0 8px;
      }

      &:last-child {
        border-radius: 0 0 8px 0;
      }
    }
  }

  &__description {
    min-width: 200px !important;
  }

  &-wrap {
    overflow-x: auto;
  }
}

.auction-status {
  background: #fafdff;
  border: 1px solid #dfeaf3;
  border-radius: 8px;
  width: 536px;

  &__item {
    text-align: center;
    width: calc(100% / 3);
    flex-grow: 1;
    padding: 13px 15px;
    position: relative;

    &:after {
      content: "";
      width: 15px;
      height: 100%;
      background: url("../../../assets/images/auction-delimiter.svg")
        center/15px 100% no-repeat;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
</style>